<template>
	<nav
		class="s-navigation bg-color-primary position-fixed left-0 width-100 z-index-100 navigation-shadow"
		v-if="has_social_links || navigation"
	>
		<h2 class="accessible-hide">Sub Navigation</h2>
		<div
			class="component c-navigation c-navigation--championship display-flex align-items-center padding-x-12 bp-768:padding-x-32 height-100"
		>
			<div
				v-if="logo"
				class="c-navigation__logo bp-768:margin-t-0 z-index-1"
			>
				<img
					:src="$root.get_image_resizer_url(`${$root.proxy}${logo.url}`)"
					:alt="logo.alt"
					class="c-navigation__logo-image"
				/>
			</div>
			<div class="display-flex flex-direction-column">
				<h1
					v-if="seoTitle"
					v-html="seoTitle"
					class="c-navigation__seo-title display-none margin-x-32 header-font font-size-24 text-on-primary bp-1024:display-block"
				></h1>
				<div
					class="c-navigation__title display-none margin-x-32 header-font font-size-18 text-transform-uppercase text-on-primary bp-1024:display-block"
					v-if="sport && sport.title !== ''"
				>
					<navigation-link
						_class="text-on-primary text-decoration-none hover:text-decoration-underline"
						:url="`/sport/${sport.slug}`"
						>{{ sport.title }}</navigation-link
					>
				</div>
			</div>
			<div class="display-flex margin-x-8">
				<championship-navigation-nav
					:champ-info-exists="champInfoExists"
					:external-ticket-link="externalTicketLink"
					:ticket-hospitality-link="ticketHospitalityLink"
				/>
			</div>
			<div class="margin-l-auto display-flex height-100">
				<navigation-drop-down
					:navigation="navigation"
					class="padding-l-4 bp-768:padding-x-16 bp-1024:padding-x-24"
					mode="navigation"
				>
					Quick Links
				</navigation-drop-down>
				<navigation-drop-down
					class="margin-l-8 bp-768:padding-x-16 bp-1024:padding-x-24"
					:navigation="navigation"
					mode="social_links"
				>
					Social Media
				</navigation-drop-down>
			</div>
		</div>
	</nav>
</template>

<script>
import NavigationLink from '@/components/common/NavigationLink';
import NavigationDropDown from '@/components/common/NavigationDropDown';
import ChampionshipNavigationNav from '@/components/championship/ChampionshipNavigationNav';

export default {
	name: 'ChampionshipNavigation',
	props: {
		sport: {
			type: Object,
			default: null,
		},
		logo: {
			type: Object,
			default: null,
		},
		navigation: {
			type: Object,
			default: null,
			required: true,
		},
		champInfoExists: {
			type: Boolean,
			required: true,
		},
		externalTicketLink: {
			type: String,
			default: '',
		},
		ticketHospitalityLink: {
			type: String,
			default: '',
		},
		seoTitle: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isMobile: false,
			ticketHospitalityObj: {
				id: null,
				link: this.ticketHospitalityLink,
				title: 'Tickets & Hospitality',
			},
		};
	},
	components: {
		NavigationLink,
		NavigationDropDown,
		ChampionshipNavigationNav,
	},
	mounted() {
		this.checkIfMobile();
		window.addEventListener('resize', this.checkIfMobile);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.checkIfMobile);
	},

	methods: {
		checkIfMobile() {
			const wasMobile = this.isMobile;
			this.isMobile = window.innerWidth < 600;

			if (this.isMobile && !wasMobile) {
				if (
					!this.navigation.items.includes(this.ticketHospitalityObj)
				) {
					this.navigation.items.unshift(this.ticketHospitalityObj);
				}
			} else if (!this.isMobile && wasMobile) {
				this.navigation.items = this.navigation.items.filter(
					item => item !== this.ticketHospitalityObj,
				);
			}
		},
	},

	computed: {
		has_social_links() {
			if (
				this.navigation.facebook !== '' ||
				this.navigation.twitter !== '' ||
				this.navigation.instagram !== '' ||
				this.navigation.youtube !== ''
			) {
				return true;
			}
			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
.s-navigation {
	height: var(--championship-header-height);
	top: 48px;
}
.c-navigation {
	&__logo-image {
		height: 60px;
	}
	&__toggle {
		&.is-toggled {
			.c-navigation__toggle-icon {
				transform: rotate(90deg);
			}
		}
	}
	&__off-canvas {
		height: calc(100vh - var(--full-header-height));
		width: 100%;
	}
}

@media screen and (min-width: 1024px) {
	.s-navigation {
		height: 105px;
	}
	.c-navigation {
		&__seo-title {
			margin-top: 0;
			margin-bottom: 0;
		}
		&__logo-image {
			height: 100px;
		}
		&__off-canvas {
			height: auto;
			width: 375px;
		}

		h1 {
			font-weight: 500;
		}
	}
}

.c_navigation {
	.c-navigation__logo {
		display: flex;
		justify-content: center;
		width: inherit;
		overflow: hidden;
	}
}
</style>

<style lang="scss">
.c-navigation--championship {
	.c-navigation__url--level-1 {
		&:hover,
		&:focus {
			svg,
			img {
				transform: translateX(3px);
			}
		}
	}
}

.navigation-shadow {
	box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.4);
}
</style>
