<template>
	<div class="component c-championship c-championship--card" v-if="data">
		<component
			:is="'NavigationLink'"
			:_class="'c-championship__url display-block text-decoration-none'"
			:url="
				data.links && data.links.length > 0
					? data.links[0].link_url
					: ''
			"
		>
			<div
				class="display-flex
					bp-540:display-block"
			>
				<div
					class="c-championship__media bg-color-light-background position-relative
						bp-540:aspect-ratio-16-9 bp-540:overflow-hidden"
				>
					<img
						v-lazy="
							$root.get_image_resizer_url(`https://d230gea5qwdu36.cloudfront.net${data.primary_image.images[0].image}`)
						"
						:alt="data.primary_image.images[0].alt"
						class="c-championship__image height-100 position-absolute top-0 left-0 object-fit-cover object-position-c transition width-100"
						v-if="
							data.primary_image &&
								data.primary_image.images.length
						"
					/>
					<img
						class="c-championship__image height-100 position-absolute top-0 left-0 object-fit-cover object-position-c transition width-100"
						src="@/assets/champ_info_img.webp"
						alt=""
						v-else
					/>
				</div>
				<div
					class="c-championship__details padding-16 display-flex
						bp-768:padding-x-0 bp-540:display-block"
				>
					<div
						class="c-championship__title display-flex align-items-center"
					>
						<span
							class="flex-item-1 header-font font-size-18 line-height-100 text-transform-uppercase transition transition-timing-function-ease
								bp-1024:font-size-30"
						>
							{{ data.title }}
						</span>
					</div>
				</div>
			</div>
		</component>
		<div class="tileOverlayTextWrapper">
			<component
				v-for="link in data.links"
				:url="link.link_url"
				:key="link.link_id"
				:is="'NavigationLink'"
				_class="c-championship__url display-block text-decoration-none tileOverlayTextLink"
				_external-icon
			>
				<span
					v-html="
						link.link_text.replaceAll(/(?:\r\n|\r|\n)/g, '<br />')
					"
				></span>
			</component>
		</div>
	</div>
</template>

<script>
import NavigationLink from '@/components/common/NavigationLink';

export default {
	name: 'NewsListCardMobile',
	components: {
		NavigationLink,
	},
	props: {
		data: {
			required: true,
			type: Object,
			default: null,
		},
	},
	data() {
		return {};
	},
	computed: {},
	methods: {},
};
</script>

<!-- NCAATIX-132 -->
<!-- Component based and largely copied from ChampionshipCard.vue, some styles may not be used -->
<style lang="css">
.tileOverlayTextDesktop,
.tileOverlayTextMobile {
	visibility: hidden;
	width: 100%;
	display: flex;
	position: absolute;
	color: white;
	opacity: 0.95;
	bottom: 0;
}
.tileOverlayTextDesktop {
	aspect-ratio: 16/9;
	top: 0;
	bottom: unset;
	pointer-events: none;
	align-items: flex-end;
}
.tileOverlayTextWrapper {
	background-color: var(--color-primary) !important;
	width: 100%;
	text-align: left;
	pointer-events: all;
}
.tileOverlayTextLink {
	color: white;
	text-decoration: none;
	padding: 8px;
	border-top: 1px solid #fff;
}
.tileOverlayTextLink:before {
	content: '\203A';
	margin-right: 8px;
}
</style>

<style lang="scss">
.c-championship--card {
	position: relative;
	&:hover,
	&:focus {
		.c-championship__image {
			transform: scale(1.1);
		}

		.c-championship__title > span {
			color: var(--color-primary);
		}
		.tileOverlayTextDesktop {
			visibility: visible !important;
			@media screen and (max-width: 539px) {
				visibility: hidden;
				display: none;
			}
		}
	}
	.c-championship {
		&__media {
			@media screen and (max-width: 539px) {
				width: 100px;
				height: 100px;
			}
		}
		&__title {
			color: var(--color-primary);
		}
		//for naming
		&__url {
			&:hover,
			&:focus {
				.c-championship__image {
					transform: scale(1.1);
				}

				.c-championship__title > span {
					color: var(--color-primary);
				}

				.tileOverlayTextDesktop {
					visibility: visible !important;
					@media screen and (max-width: 539px) {
						visibility: hidden;
						display: none;
					}
				}
			}
		}
		&__division {
			background: rgba($color: #000000, $alpha: 0.8);
			border-radius: 56px;
			height: 23px;
			line-height: 23px;
			padding: 0 16px;
			top: 16px;
			left: 16px;

			@media screen and (max-width: 539px) {
				top: 4px;
				left: 4px;
			}
		}

		&__details {
			@media screen and (max-width: 539px) {
				background-color: white;
				flex-grow: 1;
			}
		}

		&__tileOverlayTextMobile {
			@media screen and (min-width: 539px) {
				visibility: hidden;
				display: none;
			}
		}

		&__title {
			@media screen and (max-width: 379px) {
				// The size of the ticket+text is compressing the image
				max-width: 180px;
			}
		}
	}
}
</style>
