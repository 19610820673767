<template>
	<div class="component c-events c-events--list" v-if="events.length">
		<transition-group
			name="list"
			tag="ul"
			class="c-events__list reset-list row 
				bp-540:padding-t-24"
		>
			<li
				class="c-events__card transition bp-540:padding-4"
				:class="[
					{
						'col-1-of-1 bp-768:col-1-of-2 bp-1280:col-1-of-3 bp-1440:col-1-of-4':
							event.schools && event.schools.length,
					},
					{
						'col-1-of-1 bp-768:col-1-of-2 bp-1280:col-1-of-3 bp-1440:col-1-of-4':
							event.schools && !event.schools.length,
					},
					{
						'padding-b-16': index < events.length - 1,
					},
				]"
				v-for="(event, index) in filtered_events"
				:key="event.id"
			>
				<championship-event-card :event="event" />
			</li>
		</transition-group>
	</div>
</template>

<script>
import ChampionshipEventCard from '@/components/championship/ChampionshipEventCard';
import moment from 'moment-timezone';

export default {
	name: 'EventList',

	props: {
		events: {
			required: true,
			type: Array,
			default: () => [],
		},

		stateFilter: {
			type: String,
			default: 'all',
		},

		searchFilter: {
			type: String,
			default: '',
		},
	},

	components: {
		ChampionshipEventCard,
	},

	data: () => ({
		filter: {
			state: 'all',
			search: '',
		},
	}),

	computed: {
		filtered_events() {
			let _events = this.events.filter(e => {
				if (e.end_date) {
					let expirationDate;
					if(e.end_date_time) {
						// Convert from Eastern time to local time.
						expirationDate = moment.tz(e.end_date + " " + e.end_date_time, "YYYY-MM-DD HH:mm", 'America/New_York').toDate();
					}
					else {
						// Convert from Eastern time to local time.
						expirationDate = moment.tz(e.end_date, "YYYY-MM-DD", 'America/New_York').toDate();
					}

					// Filter out expired events
					if(new Date() > expirationDate) return false;
				}
				if (e.title.length < 1) return false;
				for (const key in this.filter) {
					if (key === 'search') {
						let search = this.filter.search.toLowerCase();
						let title = e.title.toLowerCase();
						let city = e.city.toLowerCase();
						let facility = e.facility.toLowerCase();
						if (search === '') continue;
						if (
							title.indexOf(search) === -1 &&
							title.includes(search) === false &&
							city.includes(search) === false &&
							facility.includes(search) === false
						) {
							return false;
						} else {
							continue;
						}
					} else if (this.filter[key] === 'all') {
						continue;
					} else if (key === 'sport') {
						if (e.sport.shortname !== this.filter.sport)
							return false;
						else continue;
					}

					if (e[key] !== this.filter[key]) return false;
				}
				return true;
			});
			return _events;
		},
	},

	watch: {
		stateFilter(newVal) {
			this.filter.state = newVal;
		},

		searchFilter(newVal) {
			this.filter.search = newVal;
		},
	},
};
</script>

<style lang="scss">
.c-events--list {
	.c-events__list {
		overflow: hidden;
	}

	.c-events__item {
		transition: all 0.5s;
	}

	.list-enter,
	.list-leave-to {
		opacity: 0;
		transform: translateY(36px);
	}

	.list-leave-active {
		position: absolute;
		display: none;
	}
}
</style>
